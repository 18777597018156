import React from 'react';
import { Helmet } from 'react-helmet';
import { OSANO_SCRIPT_URL } from 'consts/scripts';

/*
 * Google Merchant Verification Tag. Confirm with a stakeholder
 * with Google Merchant Access before removing.
 */
export const Head = () => (
  <Helmet>
    <script src={OSANO_SCRIPT_URL}></script>
    <meta
      name="google-site-verification"
      content="f-GndJuRIgKCMLdrDqlO3vZajMOTE4GZPByWx1D-5gc"
    />
    <meta
      name="google-site-verification"
      content="2SMwnCtvMmGpHmCyKZHB-JA5BAx50htbuC29GORf-Hw"
    />
    <meta
      name="google-site-verification"
      content="v9iLiN4iRbuHMWcwJIUf1uuPUjFcMGy0CmsiS6TgU5g"
    />
    <meta
      name="google-site-verification"
      content="1Ztar3U3xUDywQ-Y7ntiUZzNu0gCQTLXmpAHxRdUNUo"
    />
    <meta
      name="google-site-verification"
      content="cVwCakLNOvLxg5yBe_QGeF6JD6eKfVDWbgeDDClDMps"
    />
    <link rel="dns-prefetch" href="//www.googletagmanager.com" />
  </Helmet>
);
